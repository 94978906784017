// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookbook-js": () => import("./../../../src/pages/cookbook.js" /* webpackChunkName: "component---src-pages-cookbook-js" */),
  "component---src-pages-favorites-js": () => import("./../../../src/pages/favorites.js" /* webpackChunkName: "component---src-pages-favorites-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-promos-js": () => import("./../../../src/pages/promos.js" /* webpackChunkName: "component---src-pages-promos-js" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-pages-valentines-js": () => import("./../../../src/pages/valentines.js" /* webpackChunkName: "component---src-pages-valentines-js" */),
  "component---src-templates-promos-template-js": () => import("./../../../src/templates/promos-template.js" /* webpackChunkName: "component---src-templates-promos-template-js" */),
  "component---src-templates-recipes-template-js": () => import("./../../../src/templates/recipes-template.js" /* webpackChunkName: "component---src-templates-recipes-template-js" */),
  "component---src-templates-valentine-template-js": () => import("./../../../src/templates/valentine-template.js" /* webpackChunkName: "component---src-templates-valentine-template-js" */)
}

